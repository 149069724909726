<template>
        <vs-sidebar click-not-close position-right parent="#app" class="hours-sidebar" spacer v-model="localActiveSidebar">
            <div class="mt-6 flex items-center justify-between px-6" slot="header">
                <h4 class="text-white"> {{ this.$t('Información del pedido') }} </h4>
                <feather-icon icon="XIcon" @click.stop="localActiveSidebar = false" class="cursor-pointer"></feather-icon>
            </div>

            <VuePerfectScrollbar class="scroll-area--data-list-add-new">
                <div class="p-6">
                    <!-- {{products}} -->
                    <div class="vx-col w-full mt-2 mb-5">
                        <h4 for="address" class="mt-2 mb-4"><strong><b>{{ this.$t('Información') }}</b></strong> </h4>
                        <ul class="list mb-5 w-1/2">
                            <li class="list__item">
                                <feather-icon :icon="'UserIcon'" class="w-5 h-5 mr-1"></feather-icon>
                                <span style="line-break: anywhere; white-space: normal; min-width: 100%;">
                                    <p class="pe">{{ this.$t('CNPJ Cliente') }} </p>
                                    {{products.user_identification}}
                                </span>
                            </li>
                            <li class="list__item">
                                <feather-icon :icon="'UserIcon'" class="w-5 h-5 mr-1"></feather-icon>
                                <span style="line-break: anywhere; white-space: normal; min-width: 100%;">
                                    <p class="pe">{{ this.$t('Nombre Cliente') }}</p>
                                    {{products.name}}
                                </span>
                            </li>
                            <li class="list__item">
                                <feather-icon :icon="'CalendarIcon'" class="w-5 h-5 mr-1"></feather-icon>
                                <span style="line-break: anywhere; white-space: normal; min-width: 100%;">
                                    <p class="pe">{{ this.$t('Fecha Compra') }} </p>
                                    {{products.update_on}}
                                </span>
                            </li>
                            <li class="list__item">
                                <feather-icon :icon="'MinusSquareIcon'" class="w-5 h-5 mr-1"></feather-icon>
                                <span style="line-break: anywhere; white-space: normal; min-width: 100%;">
                                    <p class="pe">{{ this.$t('Promoción') }} </p>
                                    <div v-if="products.promotions_redeem">
                                        <div v-if="products.promotions_redeem.length > 0">
                                            {{ $t('SI') }}
                                        </div>
                                        <div v-else>
                                            {{ $t('NO') }}
                                        </div>
                                    </div>
                                </span>
                            </li>
                        </ul>
                        <ul class="list mb-5 w-1/2">
                            <li class="list__item">
                                <feather-icon :icon="'ShoppingBagIcon'" class="w-5 h-5 mr-1"></feather-icon>
                                <span style="line-break: anywhere; white-space: normal; min-width: 100%;">
                                    <p class="pe">{{ this.$t('Cantidad Productos') }} </p>
                                    {{products.shipping }}
                                </span>
                            </li>
                            <li class="list__item">
                                <feather-icon :icon="'DollarSignIcon'" class="w-5 h-5 mr-1"></feather-icon>
                                <span style="line-break: anywhere; white-space: normal; min-width: 100%;">
                                    <p class="pe">{{ this.$t('Subtotal Compra') }} </p>
                                    {{products.subTotal | coin}}
                                </span>
                            </li>
                            <li class="list__item">
                                <feather-icon :icon="'DollarSignIcon'" class="w-5 h-5 mr-1"></feather-icon>
                                <span style="line-break: anywhere; white-space: normal; min-width: 100%;">
                                    <p class="pe">{{ this.$t('Total Compra') }} </p>
                                    {{products.grandTotal  | coin}}
                                </span>
                            </li>
                            <li class="list__item" v-if="products.promotions_redeem.length > 0">
                                <feather-icon :icon="'MinimizeIcon'" class="w-5 h-5 mr-1"></feather-icon>
                                <span style="line-break: anywhere; white-space: normal; min-width: 100%;">
                                    <p class="pe">{{ this.$t('Promociones') }} </p>
                                    <div v-for="promo in products.promotions_redeem" v-bind:key="promo.promotions_redeem" class="vx-row">
                                        <div class="vx-col">
                                            <p> {{ promo.name_type_promotions }} ({{ promo.promotion_code }}) </p>
                                        </div>
                                    </div>
                                </span>
                            </li>
                        </ul>
                    </div>
                    <hr>
                    <h4 for="address" class="mt-2"><b>{{ this.$t('Productos') }}</b></h4>
                    <div v-for="product in products.orders_items" v-bind:key="product.orders_items" class="vx-row mt-2">
                        <!-- imagen del prodcuto -->
                        <div class="vx-col w-1/6 p-0">
                            <img :src="product.image" width="70"/>
                        </div>

                        <!-- nombre del producto -->
                        <div class="vx-col w-1/2">
                            <p class="text-sm"> {{ product.sku | sku }} <br> {{ product.title }} </p>
                        </div>

                        <!-- cantidad de producto -->
                        <div class="vx-col w-1">
                            <p class="text-sm"> x{{ product.quantity }} </p>
                        </div>

                        <!-- precio -->
                        <div class="vx-col w-1/5">
                            <p class="text-sm"> {{ product.price | coin }} </p>
                        </div>
                    </div>                    

                </div>

            </VuePerfectScrollbar>
            
            <div class=" items-center p-6" slot="footer">
                <button class="vs-component vs-button float-right bg-info vs-button-filled mb-2" @click="localActiveSidebar = false">
                    {{ this.$t('Cerrar') }}
                </button>
            </div>
        </vs-sidebar>
</template>

<script lang="ts">
import Vue from 'vue';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';

export default Vue.extend({
    props: {
        sidebar: {
            required: true,
            type: Boolean,
        },

        data: {
            required: true,
        },

    },

    computed: {
        localActiveSidebar: {
            get: function() {
                return this.sidebar;
            },
            set: function( ) {                
                this.$emit( 'closeSidebar', true );
            }
        },

        products: {
            get: function() {
                return this.data;
            }
        }
    },

    components: {
        VuePerfectScrollbar,
    }
})
</script>

<style lang="scss" scoped>
.hours-sidebar {
    ::v-deep .vs-sidebar--background {
        z-index: 52010;
    }
    
    ::v-deep .vs-sidebar {
        position: fixed;
        z-index: 52010;
        width: 450px;
        max-width: 90vw;
        .vs-sidebar--header {
            background: #841910;
            color: #FFF;
        }
    }
}

.list {
    display: inline-table !important;
    font-size: 0.9rem;
}
.scroll-area--data-list-add-new {
    height: calc(100% - 4.3rem);
}

.pe {
    font-weight: 600;
}
</style>