<template>
    <div>
        <div v-if="calledFrom === 'orders'" class="card card_total1">
            <div class="card-body">
                <div class="row pt-4 px-4">
                    <div class="col-md-12" style="width:100%; margin-left: 20px;">
                        <div class="row flex justify-between text-center">
                            <div style="text-align: left">
                                <p>{{ this.$t('Año') }}</p>
                                <vs-select v-model="year" @change="findStatistics(year)" class="w-24 inline-block">
                                    <vs-select-item :key="index" :value="item" :text="item" v-for="(item, index) in years" />
                                </vs-select>
                            </div>
                            <div class="row flex">
                                <div class="col-sm-3 v-line1" style="padding-left:20px;padding-right: 20px;">{{ this.$t('Total Ventas')}}<h3>R$ {{statistics.total}}</h3></div>
                                <div class="col-sm-3 v-line1">{{ this.$t('Cantidad Pedidos')}}<h3> {{statistics.total_orders}}</h3></div>
                                <div class="col-sm-3 v-line1">{{ this.$t('Cantidad Clientes')}}<h3>{{statistics.total_clients}}</h3></div>
                                <div class="col-sm-3 v-line1"></div>
                        
                            </div>
                        </div>
                    </div>
                    <div class="row" style="width:100%">
                        <hr style="margin: 15px;">
                        <div class="col-md-6 text-center justify-end inline-block" style="width: 45%;margin-left: 15px;">
                            <vs-table style="text-align: right" :max-items="12" :data="mount_data" :noDataText=" this.$t( 'Datos no disponibles' ) ">
                                <template slot="thead" >
                                    <vs-th class="mount_table">{{ this.$t('Mes') }}</vs-th>
                                    <vs-th class="mount_table">{{ this.$t('Total') }}</vs-th>
                                    <vs-th class="mount_table">{{ this.$t('Q. Pedidos') }}</vs-th>
                                    <vs-th class="mount_table">{{ this.$t('Q. Clientes') }}</vs-th>
                                </template>

                                <template slot-scope="{data}">
                                    <vs-tr v-bind:key="indextr" v-for="(tr, indextr) in data">
                                        <vs-td :data="tr.months" class="td_">
                                            <span>{{ tr.months }}</span>
                                        </vs-td>
                                        <vs-td :data="tr.total" class="td_">
                                            <span class="text-red">R$ </span>
                                            <span>{{ tr.total }}</span>
                                        </vs-td>
                                        <vs-td :data="tr.orders" class="td_">
                                            {{tr.orders }}
                                        </vs-td>
                                        <vs-td :data="tr.clients" class="td_">
                                                <span>{{ tr.clients }}</span>
                                        </vs-td>

                                    </vs-tr>
                                </template>
                            </vs-table>
                        </div>
                        <div class="pl-2 col-md-6 text-center justify-end inline-block">
                            <template >
                                <div>
                                    <apexchart v-if="appex" width="160%" height="300" type="bar" :options="options" :series="series"></apexchart>
                                </div>
                            </template>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
        <vx-card class="mt-10 card_total1" id="orders-history-card">
            <div v-if="calledFrom != 'orders'" class="w-full flex justify-between items-center">
                <h2 class="text-info mb-10">{{ this.$t('Historial de pedidos')}}</h2>
                <!-- <vs-button @click="refreshOrders()" color="transparent" text-color="gray">
                    <feather-icon icon="RefreshCwIcon" :class="{ 'animate-spin': refreshingOrders }"></feather-icon>
                </vs-button> -->
            </div>
            <div style="text-align: end">
                <p class="con-select w-64 inline-block mr-5">{{ this.$t('Items por página') }}</p>
                <vs-select  v-model="pagination.page_size" class="w-24 inline-block">
                    <vs-select-item v-bind:key="i" :value="item" :text="item" v-for="(item, i) in maxItemsVals" />
                </vs-select>
            </div>
            <div class="w-full my-5"  v-if="calledFrom === 'orders'">
                <div>
                    <h5 class="flex w-1/2">{{ this.$t('Filtrar') }}</h5>
                    <!-- <h5 class="flex w-1/2 justify-end">{{ this.$t('Exportar datos') }}</h5> -->
                </div>

                <div class="md:flex md:space-x-4 sm:space-x-2">
                    <div class="md:flex md:space-x-4 sm:space-x-2">
                        <div class="vx-col w-32">
                            <vs-input v-model="filter.id" id="o_id" class="w-full" placeholder="N° Orden" />
                        </div>
                        <flat-pickr v-show="export_filter" :config="configFromdateTimePicker" v-model="filter.init_date" :placeholder=" this.$t('Fecha de inicio') " @on-change="onFromChange" />

                        <flat-pickr v-show="export_filter" :config="configTodateTimePicker" v-model="filter.finish_date" :placeholder=" this.$t('Fecha final')" @on-change="onToChange" />
                        
                        
                        <div v-show="export_filter" class="vx-col w-48">
                            <vs-input v-model="filter.identification" id="id_client" class="w-full" placeholder="CNPJ Cliente" />
                        </div>
                        <vs-select v-show="export_filter" v-model="filter.status" class="vx-col w-32">
                            <vs-select-item v-bind:key="item.id" :value="item.id" :text="item.status" v-for="item in status_order" />
                        </vs-select>
                        <feather-icon v-show="delete_filter" v-on:click="clearSearch()" icon="XCircleIcon" style="width: 18px; color: darkred;" class="mt-2 mb-2 mr-2" />
                        <vs-button v-on:click="findOrders(true, null)" class="w-32 rounded-full inline-block">{{ this.$t('Filtrar') }}</vs-button>
                        <!-- eslint-disable -->
                    </div>
                    <div class="md:flex justify-end inline-block" style="margin-left: auto !important;">
                        <vs-button :disabled="orders_all.length == 0" v-on:click="exportData()" class="rounded-full inline-block">{{ this.$t('Exportar') }}</vs-button>
                    </div>
                </div>
            </div>
            <vs-table :max-items="maxItems" :data="orders" :noDataText=" this.$t( 'Datos no disponibles' ) ">
                <template slot="thead">
                    <vs-th>Nº</vs-th>
                    <vs-th sort-key="update_on">{{ this.$t('Fecha') }}</vs-th>
                    <vs-th sort-key="shop_id">{{ this.$t('Tienda') }}</vs-th>
                    <vs-th>{{ this.$t('Cliente') }}</vs-th>
                    <vs-th>{{ this.$t('Promoción') }}</vs-th>
                    <vs-th>{{ this.$t('Estado') }}</vs-th>
                    <vs-th>{{ this.$t('Total') }}</vs-th>
                    <vs-th v-if="calledFrom === 'orders'">{{ this.$t('Método de pago') }}</vs-th>
                    <vs-th v-if="calledFrom === 'orders'">{{ this.$t('Pedido') }}</vs-th>
                    <vs-th></vs-th>
                </template>

                <template slot-scope="{data}">
                    <vs-tr v-bind:key="indextr" v-for="(tr, indextr) in data">

                        <!-- id -->
                        <vs-td :data="tr.id">
                            <span class="text-blue">{{ tr.id }}</span>
                        </vs-td>

                        <!-- fecha -->
                        <vs-td :data="tr.update_on">
                            {{ tr.update_on }}
                        </vs-td>

                        <!-- tienda -->
                        <vs-td :data="tr.shop_id">
                            {{ getStore( tr.shop_id ) ? getStore( tr.shop_id ).name : '' }}
                        </vs-td>

                        <!-- cliente -->
                        <vs-td :data="tr.name">
                            {{ `${tr.name} ${tr.lastname}` }}
                        </vs-td>

                        <vs-td :data="tr.promotions_redeem">
                            <div v-if="tr.promotions_redeem.length > 0">
                                {{ $t('SI') }}
                            </div>
                            <div v-else>
                                {{ $t('NO') }}
                            </div>
                        </vs-td>

                        <!-- estado -->
                        <vs-td :data="tr.status">
                            <div class="flex">
                                <div :class="`bg-${getColorStatusChip(tr.status)} w-6 h-6 rounded-full inline-block mr-2`">&nbsp;</div>&nbsp;&nbsp;
                                <span>{{ tr.status_reference }}</span>
                            </div>
                        </vs-td>

                        <!-- total -->
                        <vs-td :data="tr.grandTotal">
                            <b>{{ tr.grandTotal | coin }}</b>
                        </vs-td>

                        <!-- metodo de pago -->
                        <vs-td v-if="calledFrom === 'orders'" :data="tr.payment_method">
                            {{ tr.payment_method }}
                        </vs-td>

                        <!-- pedido -->
                        <vs-td v-if="calledFrom === 'orders'" :data="tr.orders_items">
                            <div
                                style="background: #dee2e5; color: #6f758b;"
                                class="w-48 p-1 mb-1 rounded-lg text-center cursor-pointer"
                                @click="showProducts( tr.id )"
                            >
                                <feather-icon icon="EyeIcon" class="align-middle" />
                                {{ $t('Información') }}
                            </div>
                        </vs-td>

                        <!-- expandir -->
                        <vs-td :data="tr.id">
                            <vs-button v-if="calledFrom !== 'orders'" color="transparent" text-color="#666" to="/app/orders">
                                <feather-icon icon="MoreVerticalIcon" />
                            </vs-button>
                        </vs-td>
                    </vs-tr>
                </template>
            </vs-table>
            <vs-pagination :total="pagination.total" class="mt-3" v-model="pagination.current"></vs-pagination>
        </vx-card>

        <SidebarShowProducts v-if="sidebar" :sidebar="sidebar" :data="productsToShow" @closeSidebar="closeSidebar()"/>
    </div>

</template>

<script lang="ts">
import Vue       from 'vue';
import Cookies   from 'js-cookie';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import VueApexCharts from 'vue-apexcharts'
import SidebarShowProducts from './SidebarShowProducts.vue';

export default Vue.extend({
    props: ['title', 'calledFrom'],
    
    data() {
        return {
            storesSelected   : [],
            sidebar          : false,
            productsToShow   : {},
            refreshingOrders : false,
            configFromdateTimePicker : {
                minDate: null,
                maxDate: null
            },
            configTodateTimePicker: {
                minDate: null
            },
            status_order        : [
                { status:"ABERTO", id: 13}, 
                { status:"CANCELADO", id: 16},
                { status:"ATENDIDO", id: 17}
            ],
            selectedFormat : "Estado",
            pagination     : { total: 1, current: 1, page_size: 20, name: '' },
            maxItems       : 20,
            maxItemsVals   : [10,20,30,40,50],
            newData: [],
            orders: [],
            export_filter: true,
            filter: {
                id: null,
                identification: null,
                status: null,
                finish_date: null,
                init_date: null,
            },
            delete_filter: false,
            orders_all: [],
            active_filter: false,
            statistics: {total_orders:0,total:0,total_clients:0},
            years: [],
            year: 0,
            mount_data_total: [
                'Janeiro',
                'Fevereiro',
                'Março',
                'Abril',
                'Maio',
                'Junho',
                'Julho',
                'Agosto',
                'Setembro',
                'Outubro',
                'Novembro',
                'Dezembro'
            ],
            mount_data: ['','','','','','','','','','','',''],
            options: {
                chart: {
                    id: 'charts-orders'
                },
                xaxis: {
                    categories: []
                }
            },
            series: [{
                name: 'Ordens',
                data: []
            }],
            appex: false,
        }
    },

    async created() {
        this.refreshingOrders = true;
        var today = new Date();
        var fullyear = today.getFullYear();
        this.year = fullyear.toString();
        for (let i=0; i<7;i++){
            this.years.push(fullyear - i)
        }
        if (this.calledFrom != 'orders'){
            this.pagination.page_size = 10;
        } else {
            this.options.xaxis.categories.push(...this.mount_data_total)
            this.findStatistics(this.year.toString());
        }
        const promises = [
            this.$store.dispatch( 'stores/getAllStores' )
        ];
        this.findOrders(true, null);
        Promise.all( promises )
            .then( () => {
                this.refreshingOrders = false;
            })
    },

    watch: {
        storesSelected() {
            this.handleStoresSelected();
        },
        'pagination.current'() { this.findOrders(true, 'page'); },
        'pagination.page_size'() { this.findOrders(true, 'page'); },
        'filter.status'(val){ if (val){ this.delete_filter = true }  },
        'filter.identification'(val) { if (val){ this.delete_filter = true }},
        'filter.finish_date'(val){ if (val){ this.delete_filter = true }  },
        'filter.init_date'(val) { if (val){ this.delete_filter = true }},
        'filter.id'(val) { if (val){ 
            this.delete_filter = true; 
            this.export_filter = false 
        } else {  
            this.export_filter = true 
            }
        }/* ,
        'year'(v){if (v){ this.findStatistics(v)}} */
    },

    methods: {
        async findStatistics(year){
            this.appex = false
            let payload = { year: year}
            let statistics = await this.$store.dispatch( 'tracking/getOrdersStatistics', payload )
            this.series[0].data = []
            if (statistics.status == 200){
                this.statistics = statistics.data.totales;
                this.mount_data = [];
                for (let i=0; i<statistics.data.statistics.length; i++){
                    this.mount_data.push({
                        months: this.$t(statistics.data.statistics[i].months),
                        total: statistics.data.statistics[i].total,
                        clients: statistics.data.statistics[i].clients,
                        orders: statistics.data.statistics[i].orders
                    })
                }
                for (let i=0; i<this.mount_data_total.length; i++){
                    this.series[0].data.push({
                        x: this.options.xaxis.categories[i],
                        y: statistics.data.statistics[i].orders,
                        fillColor: '#e32112'
                    })
                }
            }
            this.appex = true
        },
        clearSearch(){
            this.filter = {
                id: null,
                identification: null,
                status: null,
                finish_date: null,
                init_date: null,
            };
            this.delete_filter = false;
            this.findOrders(true, null);
        },
        async findOrders(val, p){
            var payload = {
                page      : this.pagination.current,
                page_size : this.pagination.page_size
            };
            if (!val && !p) {
                payload['page'] = 1
                payload['page_size'] = 10000
            }
            let orders = {}
            if (this.filter.id) {
                orders = await this.$store.dispatch( 'tracking/getOrderByID', this.filter.id )
            } else {
                if( this.filter.init_date || this.filter.finish_date ) {
                    if( this.filter.init_date && !this.filter.finish_date ) {
                        this.$vs.notify({
                            title: this.$t('Debe ingresar la fecha final'),
                            text: '',
                            iconPack: 'feather',
                            icon: 'icon-alert-circle',
                            color: 'warning'
                        });
                        return;
                    } else {
                        if( !this.filter.init_date && this.filter.finish_date ) {
                           this.$vs.notify({
                            title: this.$t('Debe ingresar la fecha inicial'),
                            text: '',
                            iconPack: 'feather',
                            icon: 'icon-alert-circle',
                            color: 'warning'
                        });
                        return;
                        } else {
                            payload['init_date'] = this.filter.init_date
                            payload['finish_date'] = this.filter.finish_date
                        }
                    }
                    
                }
                if( this.filter.identification ) {
                    payload['identification'] = this.filter.identification
                }
                if( this.filter.status ) {
                    payload['status'] = this.filter.status
                }
                this.orders_all = []
                orders = await this.$store.dispatch( 'tracking/getAllOrders', payload )
            }
            if (val && !p){
                if (orders.status == 200){
                    if (!Array.isArray(orders.data)){
                        this.orders = orders.data.results
                        this.total = orders.data.total
                        this.pagination.total = orders.data.total_pages;
                        this.findOrders(false);
                    } else {
                        this.orders = []
                        this.total = 0
                        this.pagination.total = 0
                    }
                } else {
                    this.orders = []
                    this.total = 0
                    this.pagination.total = 0;
                    this.orders_all = []
                    this.$vs.notify({
                        title: this.$t('No se han encontrado datos'),
                        text: '',
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'warning'
                    });
                    return;
                }
            } else {
                
                if (orders.status == 200){
                    if (val && p){
                        if (!Array.isArray(orders.data)){
                            this.orders = orders.data.results
                            this.total = orders.data.total
                            this.pagination.total = orders.data.total_pages;
                        } else {
                            this.orders = []
                            this.total = 0
                            this.pagination.total = 0
                        }
                    }
                    if (!Array.isArray(orders.data)){
                        this.orders_all = orders.data.results
                    } else {
                        this.orders_all = []
                    }
                }
            }
           
            
        },
        getColorStatusChip( statusId ) {
            if( statusId === parseInt( process.env.VUE_APP_ORDER_FINALIZED ) ) return 'success';
            else if( statusId === parseInt( process.env.VUE_APP_ORDER_REJECTED_BY_STORE )  ) return 'danger';
            else if( statusId === parseInt( process.env.VUE_APP_ORDER_CANCEL_BY_USER )  ) return 'info';
            else if( statusId === parseInt( process.env.VUE_APP_PREPARING_ORDER )  ) return 'prep';
            else if( statusId === parseInt( process.env.VUE_APP_ORDER_SHIPPED )  ) return 'des';
            return 'warning';
        },

        change_filter(){
            this.export_filter = !this.export_filter;
        },

        getTextStatus( statusId ) {
            if( statusId === parseInt( process.env.VUE_APP_ORDER_FINALIZED ) ) return this.$t('Entregado');
            else if( statusId === parseInt( process.env.VUE_APP_ORDER_REJECTED_BY_STORE )  ) return this.$t('Rechazado');
            else if( statusId === parseInt( process.env.VUE_APP_ORDER_CANCEL_BY_USER )  ) return this.$t('Cancelado por el usuario');
            else if( statusId === parseInt( process.env.VUE_APP_PREPARING_ORDER )  ) return this.$t('Preparando');
            else if( statusId === parseInt( process.env.VUE_APP_ORDER_SHIPPED )  ) return this.$t('Despachado');
            return this.$t('En Proceso');
        },

        handleStoresSelected() {
            //console.log(this.storesSelected);
        },

        showProducts( orderId ) {
            const order         = this.orders.find( order => order.id === orderId );
            this.productsToShow = order;
            this.sidebar        = true;
        },

        closeSidebar() {
            this.productsToShow = [];
            this.sidebar        = false;
        },

        getStore( storeId ) {
            const store = this.stores.find( store => store.id === storeId );
            if( store ) return store;
            return null;
        },

        refreshOrders() {
            this.refreshingOrders = true;
            this.$store.dispatch( 'tracking/getAllOrders' ).then( () => this.refreshingOrders = false );
        },
        
        /* eslint-disable */
        onFromChange(selectedDates, dateStr, instance) {            
            this.$set(this.configTodateTimePicker, 'minDate', dateStr);
        },
        
        /* eslint-disable */
        onToChange(selectedDates, dateStr, instance) {
            this.$set(this.configFromdateTimePicker, 'maxDate', dateStr);
        },

        async exportData() {
            let data = [];            
            this.$vs.loading();
            if( !this.orders_all ) this.$vs.loading.close();
            else {
                this.$vs.loading.close();

                if( this.orders_all.length === 0 ) {
                    this.$vs.notify({
                        title: this.$t('No hay datos en esta consulta'),
                        text: '',
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'warning'
                    });
                    return;
                }

                data = this.mapData( this.orders_all );

                const headerVal   = [
                    "id",
                    "date",
                    "store_code",
                    "store_name",
                    "username",
                    "nit",
                    "city",
                    "status",
                    "subtotal",
                    "type_promotion",
                    "discount",
                    "promo",
                    "total",
                    "payment_method"
                ];
                const headerTitle = [
                    this.$t("Id orden"),
                    this.$t("Fecha"),
                    this.$t("Código de tienda"),
                    this.$t("Nombre de la tienda"),
                    this.$t("Cliente"),
                    this.$t("Identificacion cliente"),
                    this.$t("Ciudad"),
                    this.$t("Estado"),
                    this.$t("Valor compra"),
                    this.$t("Promociones Asociadas"),
                    this.$t("Descuento"),
                    this.$t("Cupón"),
                    this.$t("Total compra"),
                    this.$t("Metodo de pago")
                ];

                const cellAutoWidth = true;
                const self = this;
                
                self.exportData = data;

                import('../../../vendor/Export2Excel').then( excel => {
                    const list = self.exportData;
                    
                    const data = this.formatJson( headerVal, list );

                    excel.export_json_to_excel({
                        header: headerTitle,
                        data,
                        filename: "pedidos",
                        autoWidth: cellAutoWidth,
                        bookType: 'xlsx',
                    });
                    this.$vs.loading.close();
                });
                this.$vs.loading.close();
            }

            
        },

        mapData( data =[] ) {            
            let newData = [];          
            const user = JSON.parse( Cookies.get('userInfo') );            
            
            data.forEach( key => {
                const store = this.getStore( key.shop_id ) ? this.getStore( key.shop_id ) : null;
                let promo_redem = []
                for (let i=0; i<key.promotions_redeem.length; i++){
                    promo_redem.push(key.promotions_redeem[i].name_type_promotions + '(' + key.promotions_redeem[i].promotion_code + ')')
                }
                const dataArr = new Set(promo_redem);
                this.newData.push({
                    id             : key.id,
                    date           : key.added_on.substr(0, 10),
                    store_code      : store ? store.code : '',
                    store_name      : store ? store.name : '',
                    username       : `${ key.name } ${ key.lastname }`,
                    nit            : key.user_identification,
                    city           : key.city,
                    allied_name    : user.company.app_name,
                    status         : this.getTextStatus( key.status ),
                    subtotal       : key.subTotal,
                    type_promotion : [...dataArr].join(', '),
                    discount       : key.discount,
                    delivery_price : key.delivery_price,
                    promo          : key.promo,
                    total          : key.grandTotal,
                    payment_method : this.$t( key.payment_method ),
                });
            });
            
            return this.newData;
            
        },

        formatJson( filterVal=[], jsonData=[] ) {
            return jsonData.map( v => filterVal.map( j => {
                return v[j];
            }) );
        },
    },

    computed: {
        stores() {
            return this.$store.state.stores.stores;
        },
    },

    components: {
        SidebarShowProducts,
        flatPickr,
        VueApexCharts
    },
})
</script>
<style lang="scss" scoped>
.flatpickr-input{
    max-width: 150px !important;
}
.td_{
    padding: 0px !important;
    padding-right: 10px !important;
}
.mount_table{
    background-color: #841910;
    color: white;
}
</style>